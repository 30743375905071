/* --------------------------------
   form
-------------------------------- */

form
{
	@extend %pos-relative;

	.input-wrp
	{
		@extend %pos-relative;
		@extend %display-block;
		margin-bottom: 10px;

		i
		{
			@extend %pos-absolute;
			right: 0;
			top: 0;
			line-height: rem-calc(59px);
			font-size: rem-calc(18px);
			color: $white;
		}
	}

	.textfield,
	button[type=submit]
	{
		box-shadow: none;
		outline: none;
	}

	.textfield
	{
		@extend %display-block;
		@extend %width-100;
		line-height: 1.4;
		font: {
			size: rem-calc(14px);
			family: inherit;
			weight: inherit;
		};
		color: #9f9f9f;
		background: 0 none;
		background: transparent;
		border: none;
		border-bottom: 1px solid #b7b7b7;
		border-radius: 0;
		padding-top: rem-calc(20px);
		padding-bottom: rem-calc(20px);
		appearance: none;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);

		@include placeholder
		{
			color: #9f9f9f;
			@include transition(color 0.3s ease-in-out);
		}

		&:hover,
		&:focus
		{
			border-color: $primary-color;
		}
	}

	textarea
	{
		resize: vertical;
		min-height: 160px;
	}

	i + .textfield { padding-right: 25px; }

	button[type=submit] { margin-top: 15px; }

	&.form-horizontal
	{
		.cell
		{
			&:first-child
			{
				padding-right: 20px;
				width: 95%;
			}
		}

		.input-wrp
		{
			margin-bottom: 0;

			i { line-height: rem-calc(45px); }
		}

		.textfield
		{
			padding-top: rem-calc(13px);
			padding-bottom: rem-calc(13px);
		}

		button[type=submit]
		{
			main & { margin-top: 0; }
		}
	}

	&.f1
	{
		.textfield
		{
			border-color: rgba($white,0.37);

			&:hover,
			&:focus
			{
				border-color: $white;
				color: $white;

				@include placeholder { color: $white; }
			}
		}

		textarea { min-height: 110px; }

		button[type=submit].custom-btn.primary:not(:hover):not(:focus) { color: $white; }
	}
}