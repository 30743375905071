/* --------------------------------
   start screen
-------------------------------- */

#start-screen
{
	@extend %pos-relative;
	@include transition( padding 0.3s ease-in-out );
}

.start-screen--style-1
{
	height: 100vh;
	min-height: 560px;
}

.start-screen--style-2
{
	height: auto;
	padding-top: 70px;

	.slide
	{
		figure
		{
			@extend %pos-relative;
			padding-top: 100%;

			img
			{
				@extend %block-absolute--full;
				@extend %bg-cover;
			}
		}

		.logo
		{
			@extend %pos-absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 40%;
			max-width: 150px;
		}
	}

	.owl-dots
	{
		@extend %pos-absolute;
		bottom: 20px;
		left: 0;
		@extend %width-100;
	}
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	.start-screen--style-2 { padding-top: 90px; }
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{
	
}