/* --------------------------------
   feature
-------------------------------- */

.feature
{
	.feature__inner { margin-bottom: -30px; }

	&__item
	{
		margin-bottom: 30px;

		&__ico
		{
			@extend %display-inline-block;
			@extend %v-top;

			&:before
			{
				content: "";
				@extend %display-inline-block;
				@extend %v-middle;
				@extend %bg-ico;
			}
		}

		&__title
		{
			line-height: 1.2;
			font-weight: 600;
		}
	}
}

.feature--style-1
{
	.feature__item
	{
		@extend %text-center;

		&__ico
		{
			height: 77px;
			line-height: 77px;

			&--1:before
			{
				width: 76px;
				height: 77px;
				background-image: url(../img/ico/ico_1.png);
			}

			&--2:before
			{
				width: 77px;
				height: 76px;
				background-image: url(../img/ico/ico_2.png);
			}

			&--3:before
			{
				width: 83px;
				height: 67px;
				background-image: url(../img/ico/ico_3.png);
			}

			&--4:before
			{
				width: 71px;
				height: 67px;
				background-image: url(../img/ico/ico_5.png);
			}

			&--5:before
			{
				width: 81px;
				height: 72px;
				background-image: url(../img/ico/ico_4.png);
			}
		}
	}
}

.feature--style-2
{
	.feature__item
	{
		&__ico
		{
			height: 51px;
			margin-bottom: 20px;

			&--1:before
			{
				width: 46px;
				height: 51px;
				background-image: url(../img/ico/ico_6.png);
			}

			&--2:before
			{
				width: 52px;
				height: 51px;
				background-image: url(../img/ico/ico_7.png);
			}

			&--3:before
			{
				width: 40px;
				height: 51px;
				background-image: url(../img/ico/ico_8.png);
			}

			&--4:before
			{
				width: 40px;
				height: 51px;
				background-image: url(../img/ico/ico_9.png);
			}

			&--5:before
			{
				width: 52px;
				height: 51px;
				background-image: url(../img/ico/ico_10.png);
			}

			&--6:before
			{
				width: 51px;
				height: 47px;
				background-image: url(../img/ico/ico_11.png);
			}
		}
	}

	[class^="col-"]:nth-of-type(2n+1),
	[class*=" col-"]:nth-of-type(2n+1)
	{
		.feature__item
		{
			.row
			{
				flex-direction: row-reverse;
				@extend %text-right;
			}
		}
	}
}

.feature--style-3
{
	.feature__item
	{
		&__ico
		{
			height: 77px;
			line-height: 77px;

			&--1:before
			{
				width: 76px;
				height: 77px;
				background-image: url(../img/ico/ico_1_1.png);
			}

			&--2:before
			{
				width: 77px;
				height: 76px;
				background-image: url(../img/ico/ico_2_1.png);
			}

			&--3:before
			{
				width: 83px;
				height: 67px;
				background-image: url(../img/ico/ico_3_1.png);
			}

			&--4:before
			{
				width: 71px;
				height: 67px;
				background-image: url(../img/ico/ico_5_1.png);
			}

			&--5:before
			{
				width: 81px;
				height: 72px;
				background-image: url(../img/ico/ico_4_1.png);
			}
		}

		&__title
		{
			margin-top: 25px;
			margin-bottom: 25px;
			color: $white;
		}

		p
		{
			margin-top: 25px;
			margin-bottom: 25px;

			&:first-child { margin-top: 0; }
			&:last-child  { margin-bottom: 0; }
		}
	}

	.custom-btn { color: $white; }
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	.section--feature
	{
		background: url(../img/bg_01.png) no-repeat center top -50px;
	}
}

@include min-screen($lg-width)
{
	.section--feature { padding-top: 140px; }

	.feature--style-2
	{
		.feature__item
		{
			margin-bottom: 60px;

			&__ico { margin-bottom: 0; }
		}
	}
}

@include min-screen($xl-width)
{
	.section--feature
	{
		>.section
		{
			margin-top: 100px;
		}
	}
}