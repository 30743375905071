/* --------------------------------
   team
-------------------------------- */

.team
{
	&__inner { }

	&__item
	{
		@extend %pos-relative;

		figure
		{
			@extend %pos-relative;
			overflow: hidden;
			@extend %width-100;
			@extend %height-0;
			margin: 0;
			padding-top: percentage(293/297);

			>*
			{
				@extend %block-absolute--full;
				@include transition( opacity 0.3s ease-in-out );
			}
		}

		img
		{
			@extend %bg-cover;
			opacity: .8;
			transform: translateZ(0);
		}

		&--text
		{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			@extend %height-100;
		}

		&__inner
		{
			background-color: $primary-color;
			@include transition( background-color 0.3s ease-in-out );
		}

		&__descriptiom
		{
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			@extend %text-left;
			text-decoration: none;
			line-height: 1;
			color: $white;
			padding: 20px;
		}

		&__title
		{
			font: {
				size: rem-calc(20px);
				weight: 600;
				family: $secondary-fontFamily;
			};
		}

		&__subtitle
		{
			font-size: rem-calc(12px);
		}
	}

	&__item--text { padding: 30px 0; }
}