/* --------------------------------
   comments list
-------------------------------- */

.comments-list
{
	@extend %pos-relative;
	margin-top: 40px;

	li { @extend %pos-relative; }

	table { @extend %width-100; }

	ul
	{
		padding-left: 20px;

		li { padding-top: 40px; }
	}
}

.comment
{
	&__header
	{
		@extend %pos-relative;
		line-height: 1;

		p
		{
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}

	&__author-avatar
	{
		width: 90px;
		height: 90px;
	}

	&__author
	{
		font-size: rem-calc(16px);
		color: #333;
	}

	&__post-date
	{
		font-size: rem-calc(12px);
		color: #a2a2a2;
	}

	&__reply-btn
	{
		font-size: rem-calc(18px);
		color: #000;
	}

	&__text { padding-top: 20px; }
}

@include min-screen($sm-width)
{
	.comments-list
	{
		ul
		{
			padding-left: 30px;
		}
	}

	.comment
	{
		&__author-avatar { margin-right: 20px; }

		&__text { padding-top: 0; }
	}
}

@include min-screen($md-width)
{
	.comments-list
	{
		&:before
		{
			content: "";
			position: absolute;
			top: 0;
			left: 45px;
			width: 1px;
			height: 100%;
			background: #d2d2d2;
		}

		ul { padding-left: 80px; }
	}
}

@include min-screen($lg-width)
{
	.comments-list
	{
		margin-top: 60px;

		&:before { left: 55px; }

		ul { padding-left: 100px; }
	}

	.comment
	{
		&__author-avatar
		{
			width: 110px;
			height: 110px;
			margin-right: 30px;
		}
	}
}

@include min-screen($xl-width)
{
	
}